
import dialogShell from '@/components/common/dialog-shell/index.vue';
import {
    defineComponent, PropType
} from 'vue';
import initForm from '@/components/view/super/feature-plan-handle-dialog/initForm';
import initConstData from '@/components/view/super/feature-plan-handle-dialog/initConstData';
import { FeaturePlanForm } from './all.type';

export default defineComponent({
    components: {
        dialogShell
    },
    props: {
        initForm: {
            type: Object as PropType<FeaturePlanForm>,
            required: true
        },
        operationType: {
            default: 'add',
            type: String,
            required: true
        }
    },
    setup(props, { emit }) {
        const {
            featurePlanTypeOption, featurePlanItems, transferButtonText, transferTitle, officeFeaturePlanItems
        } = initConstData();

        const {
            formData, formRef, submit, formRule
        } = initForm({ ...props.initForm }, props.operationType, emit);

        return {
            featurePlanTypeOption,
            featurePlanItems,
            transferButtonText,
            transferTitle,
            emit,
            formData,
            formRef,
            submit,
            formRule,
            officeFeaturePlanItems
        };
    }
});

