import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_cus_option = _resolveComponent("cus-option")
  const _component_cus_select = _resolveComponent("cus-select")
  const _component_el_radio = _resolveComponent("el-radio")
  const _component_el_transfer = _resolveComponent("el-transfer")
  const _component_el_form = _resolveComponent("el-form")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock(_component_dialog_shell, {
    title: _ctx.operationType==='add'?
            _ctx.$wordList.ProperAllTextNewFeaturePlan:
            _ctx.$wordList.ProperAllTextEditFeaturePlan,
    width: '800px',
    onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.emit('close'))),
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        rules: _ctx.formRule,
        "label-position": _ctx.$formLabelPosition,
        model: _ctx.formData,
        ref: "formRef"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$wordList.ProperAllTextFeaturePlanName,
            prop: "Name"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.formData.Name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData.Name = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$wordList.ProperAllTextFeaturePlanType,
            prop: "FeaturePlanType"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cus_select, {
                disabled: _ctx.operationType==='edit',
                modelValue: _ctx.formData.FeaturePlanType,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.formData.FeaturePlanType = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.featurePlanTypeOption, (item) => {
                    return (_openBlock(), _createBlock(_component_cus_option, {
                      key: item.value,
                      value: item.value,
                      label: item.label
                    }, null, 8, ["value", "label"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["disabled", "modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          (_ctx.formData.FeaturePlanType==='0')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                label: _ctx.$wordList.ProperAllTextRequestsOneIndoorMonitor,
                prop: "MonitorType"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_radio, {
                    modelValue: _ctx.formData.MonitorType,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.formData.MonitorType = $event)),
                    label: "1",
                    disabled: _ctx.operationType==='edit'
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$wordList.ProperAllTextOn), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "disabled"]),
                  _createVNode(_component_el_radio, {
                    modelValue: _ctx.formData.MonitorType,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.formData.MonitorType = $event)),
                    label: "0",
                    disabled: _ctx.operationType==='edit'
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$wordList.ProperAllTextOff), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "disabled"])
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true),
          _createVNode(_component_el_form_item, {
            label: _ctx.$wordList.ProperAllTextFeature,
            prop: "Items"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_transfer, {
                modelValue: _ctx.formData.Items,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.formData.Items = $event)),
                data: _ctx.formData.FeaturePlanType==='1' ? _ctx.officeFeaturePlanItems : _ctx.featurePlanItems,
                titles: _ctx.transferTitle,
                "button-texts": _ctx.transferButtonText,
                key: _ctx.formData.FeaturePlanType
              }, null, 8, ["modelValue", "data", "titles", "button-texts"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["rules", "label-position", "model"])
    ]),
    _: 1
  }, 8, ["title", "onSubmit"]))
}