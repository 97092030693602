import {
    reactive, ref, Ref,
    watch
} from 'vue';
import HttpRequest from '@/util/axios.config';
import { FeaturePlanForm } from './all.type';

const formRule = {
    Name: [
        { required: true, message: WordList.ProperAllTextFeaturePlanNameRule, trigger: 'blur' }
    ],
    FeaturePlanType: [
        { required: true, message: WordList.ProperAllTextFeaturePlanTypeRule, trigger: 'blur' }
    ],
    MonitorType: [
        { required: true, message: WordList.ProperAllTextMonitorRule, trigger: 'blur' }
    ]
    // ,
    // Items: [
    //     { required: true, message: WordList.ProperAllTextFeatureRule, trigger: 'blur' }
    // ]
};
const validateParam = (formData: FeaturePlanForm): FeaturePlanForm => {
    const copyFormData = { ...formData };
    if (copyFormData.FeaturePlanType === '1') {
        copyFormData.MonitorType = '';
    }
    return copyFormData;
};

// 初始化Form表单功能
const initForm = (propFormData: FeaturePlanForm, propOperationType: string, emit: any) => {
    const formData = reactive({ ...propFormData });
    // 切换类型时，清空之前的选项
    watch(() => formData.FeaturePlanType, () => {
        formData.Items = [];
    });

    const formRef: Ref<any> = ref();

    const close = () => {
        emit('close');
    };

    const successCallback = () => {
        emit('success');
        close();
    };

    const submit = () => {
        formRef.value.validate((valid: boolean) => {
            if (valid) {
                const params = validateParam(formData);
                if (propOperationType === 'add') {
                    HttpRequest.post('v3/web/community/featurePlan/addFeaturePlan', params, successCallback);
                } else {
                    HttpRequest.post('v3/web/community/featurePlan/editFeaturePlan', params, successCallback);
                }
            }
        });
    };

    return {
        formData,
        formRef,
        submit,
        formRule
    };
};

export default initForm;