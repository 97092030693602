import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_radio = _resolveComponent("el-radio")
  const _component_el_form = _resolveComponent("el-form")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock(_component_dialog_shell, {
    title: _ctx.$wordList.PersonuserInfo,
    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.emit('close'))),
    footerType: "customize"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, { "label-position": _ctx.$formLabelPosition }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$wordList.ProperAllTextFeaturePlanName
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formData.Name), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$wordList.ProperAllTextFeaturePlanType,
            prop: "FeaturePlanType"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formData.FeaturePlanTypeStr), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          (_ctx.formData.FeaturePlanType==='0')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                label: _ctx.$wordList.ProperAllTextRequestsOneIndoorMonitor
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_radio, {
                    modelValue: _ctx.formData.MonitorType,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData.MonitorType = $event)),
                    label: 1,
                    disabled: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$wordList.ProperAllTextOn), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_el_radio, {
                    modelValue: _ctx.formData.MonitorType,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.formData.MonitorType = $event)),
                    label: 0,
                    disabled: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$wordList.ProperAllTextOff), 1)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true),
          _createVNode(_component_el_form_item, {
            label: _ctx.$wordList.ProperAllTextFeature
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formData.Items?_ctx.formData.Items:'--'), 1)
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["label-position"])
    ]),
    _: 1
  }, 8, ["title"]))
}