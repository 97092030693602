const featurePlanTypeOption = [
    {
        label: '',
        value: ''
    },
    {
        label: WordList.ProperAllCommunity,
        value: '0'
    },
    {
        label: WordList.ProperAllOffice,
        value: '1'
    }

];
const featurePlanItems = [
    {
        key: 0,
        label: WordList.ProperAllTextFeaturePackageRoom
    },
    {
        key: 1,
        label: WordList.ProperAllTextFeatureDisablePIN
    },
    {
        key: 2,
        label: WordList.ProperAllTextFeatureDisableQR
    },
    {
        key: 3,
        label: WordList.ProperAllTextFeatureFamilyMemberControl
    }
];
// 办公没有家庭概念，隐藏家庭选项
const officeFeaturePlanItems = [
    {
        key: 0,
        label: WordList.ProperAllTextFeaturePackageRoom
    },
    {
        key: 1,
        label: WordList.ProperAllTextFeatureDisablePIN
    },
    {
        key: 2,
        label: WordList.ProperAllTextFeatureDisableQR
    }
];

const transferButtonText = [WordList.ProperAllTextRemove, WordList.FormAdd];

const transferTitle = [WordList.ProperAllTextToBeSelected, WordList.ProperAllTextSelected];

const initConstData = () => ({
    featurePlanTypeOption,
    featurePlanItems,
    transferButtonText,
    transferTitle,
    officeFeaturePlanItems
});

export default initConstData;
export {
    featurePlanItems
};