import * as initConstData from '@/components/view/super/feature-plan-handle-dialog/initConstData';
import * as initForm from '@/components/view/super/feature-plan-handle-dialog/initForm';
import index from './index.vue';
import * as IFeaturePlanHandleDialog from './all.type';

export default index;
export {
    IFeaturePlanHandleDialog,
    initConstData,
    initForm
};