
import dialogShell from '@/components/common/dialog-shell/index.vue';
import {
    defineComponent, PropType
} from 'vue';
import { IFeaturePlanHandleDialog } from '@/components/view/super/feature-plan-handle-dialog';
import initData from './initData';

export default defineComponent({
    components: {
        dialogShell
    },
    props: {
        initForm: {
            type: Object as PropType<IFeaturePlanHandleDialog.FeaturePlanForm>,
            required: true
        }
    },
    setup(props, { emit }) {
        const { formData } = initData({ ...props.initForm });
        return {
            formData,
            emit
        };
    }
});

