import { reactive, ref, Ref } from 'vue';
import { IFeaturePlanHandleDialog } from '@/components/view/super/feature-plan-handle-dialog';
import { featureItemsTurn, featurePlanTypeTurn } from '@/views/super/feature-plan/initConstData';

const initData = (detail: IFeaturePlanHandleDialog.FeaturePlanForm) => {
    const detailData = reactive({ ...detail });
    const formData = ref<IFeaturePlanHandleDialog.FeaturePlanInfoData>({
        Name: '',
        MonitorType: '',
        FeaturePlanType: '',
        Items: '',
        ID: '',
        FeaturePlanTypeStr: ''
    });
    formData.value.Name = detailData.Name;
    formData.value.MonitorType = (detailData.MonitorType) as ''|0|1;
    formData.value.FeaturePlanType = detailData.FeaturePlanType;
    formData.value.FeaturePlanTypeStr = featurePlanTypeTurn(detailData.FeaturePlanType);
    formData.value.Items = featureItemsTurn(detailData.Items);
    return {
        formData
    };
};

export default initData;