import { FeaturePlanTypeOption } from '@/views/super/feature-plan/all.type';
import { ListHeaderItem } from '@/components/common/list';
import { initConstData as initConstDataToHandleDialog } from '@/components/view/super/feature-plan-handle-dialog/index';

const featurePlanTypeOption: Array<FeaturePlanTypeOption> = [
    {
        label: WordList.SelectAll,
        value: 'all'
    },
    {
        label: WordList.ProperAllCommunity,
        value: '0'
    },
    {
        label: WordList.ProperAllOffice,
        value: '1'
    }
];

const searchKeyList = [
    {
        label: WordList.ProperAllTextFeaturePlanName,
        name: 'Name'
    },
    {
        label: WordList.ProperAllTextFeaturePlanID,
        name: 'ID'
    }
];

const headers: Array<ListHeaderItem> = [
    {
        label: WordList.ProperAllTextFeaturePlanID,
        name: 'ID'
    }, {
        label: WordList.ProperAllTextFeaturePlanName,
        name: 'Name'
    }, {
        label: WordList.ProperAllTextFeaturePlanType,
        name: 'Type',
        type: 'customize'
    }, {
        label: WordList.ProperAllTextFeature,
        name: 'Items',
        type: 'customize'
    }
];

const featurePlanTypeTurn = (val: string) => {
    let str = '';
    featurePlanTypeOption.forEach((option: FeaturePlanTypeOption) => {
        if (option.value === val) {
            str = option.label;
        }
    });
    return str;
};

const featureItemsTurn = (val: Array<number>) => {
    const arr: Array<string> = [];
    initConstDataToHandleDialog.featurePlanItems.forEach((item) => {
        val.forEach((v) => {
            if (item.key === v) {
                arr.push(item.label);
            }
        });
    });
    return arr.length === 0 ? '--' : arr.join(',');
};

const initConstData = () => ({
    featurePlanTypeOption,
    searchKeyList,
    headers,
    featurePlanTypeTurn,
    featureItemsTurn
});

export default initConstData;
export {
    featurePlanTypeTurn,
    featureItemsTurn
};